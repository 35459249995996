#canvasForSkills{
    position: absolute;
    opacity: 0.05;
    margin-top: -60px;
    z-index: 0;
    display: none;
}
.skillContainer {
    padding: 16px;
    position: relative;
  }
  
  .skillImage {
    width: 100%;
    z-index: 22;
    &:hover {
        transform: scale(1.15);
      }
  }
  .skillItem {
    padding: 8px;
  }
