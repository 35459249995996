.decoder-text {
  &::after {
    content: '_';
    visibility: hidden;
  }
}

.decoder-text__glyph {
  opacity: 0.8;
  font-weight: var(--fontWeightRegular);
  /* font-family: var(--japaneseFontStack); */
  line-height: 0;
}
