.home {
  overflow-x: hidden;
}


.flex{
  display: flex;
}

.m-4{
  margin: 4px;
}
.m-8{
  margin: 8px;
}
.mt-4{
  margin-top: 4px;
}
.mt-8{
  margin-top: 8px;
}
.mb-4{
  margin-bottom: 4px;
}
.mb-8{
  margin-bottom: 8px;
}
.static-bar{
  color: rgb(var(--rgbAccent));
  position: fixed;
    z-index: 1;
    top: 0;
    width: 100%;
}